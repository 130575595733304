// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const [data,setData]= useState([])


  useEffect(()=>{
    axios.get(`${REST_API}webservice/staff/get-staff-data/${user.id}`)
    .then(res =>{
      // console.log("?????????",res.data.result)
      setData(res.data.result)
    }).catch(err =>console.log(err,"err"))
  },[])

  return (
    <MAvatar
    src={data?.image?  `${REST_API}${data?.image}`:'https://i.pinimg.com/originals/64/c0/7c/64c07cd56fee2c3b0423168c5b10e58c.png'}
      alt='Profile'
      // color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {/* {createAvatar(user?.displayName).name} */}
    </MAvatar>
  );
}
