import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Autocomplete,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { UploadAvatar, UploadSingleFile } from '../../../upload'
// utils
import { fData } from '../../../../utils/formatNumber'
//
// import countries from '../../../../countries';
import countries from '../../user/countries'
import ProductTimeline from './ProductTimeLine'
import { min } from 'lodash'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { PATH_DASHBOARD } from 'src/routes/paths'
// import Modal from '../../project-modal/ProjectModel';
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { styled } from '@material-ui/styles'
import { userId } from 'src/contexts/JWTContext'
import StatusDecriptionImg from './status-description-image'
import { MButton, MLinearProgress } from 'src/components/@material-extend'
import LoadingScreen from 'src/components/LoadingScreen'
import { useRef } from 'react'
import Icon from '@iconify/react'
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { UploadFile } from '@material-ui/icons'

// ----------------------------------------------------------------------
const top100Films = [
  { Pickup: 'Abuja', Supplier: 'Us', type: 'physical' },
  { Pickup: 'Lagos', Supplier: 'South Africa', type: 'Digital' },
]

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))



 const validateFile = (file) => {
    const allowedFileTypes = ['application/pdf'];
    const maxFileSize = 7 * 1024 * 1024; // 7MB
  
    if (!allowedFileTypes.includes(file.type)) {
      console.log(`File type not allowed: ${file.name}`);
      return false;
    }
  
    if (file.size > maxFileSize) {
      console.log(`File size exceeds the limit: ${file.name}`);
      return false;
    }
  
    return true;
  };

export default function StatusDescription({ home }) {
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [type, setType] = useState(false)
  const [unitCost, setUnitCost] = useState('')
  const [quantity, setQuantity] = useState('')
  const [amount, setAmount] = useState('')
  const [length, setLength] = useState(null)
  const [bredth, setBredth] = useState('')
  const [height, setHeight] = useState('')
  const [total, setTotal] = useState('')
  const [agreement, setAgreement] = useState(false)
  const [agree, setAgree] = useState(false)
  const [supCountries, setSupCountries] = useState([])
  const [pickupLocations, setPickupLocations] = useState([])
  const [forwarderFees, setForwarderFees] = useState([])
  const [totalForwarderFee, setTotalForwarderFee] = useState()
  const [subTotal, setSubTotal] = useState(0)
  const [serviceChargeData, setServiceChargeData] = useState([])
  const [totalServiceFee, setTotalServiceFee] = useState()
  const [pickupLocationFee, setPickupLocationFee] = useState()
  const [vatData, setVatData] = useState([])
  const [vatRate, setVatRate] = useState(0)
  const [save, setSave] = useState(3)
  const [viewData, setViewData] = useState([])
  const [staffVerified, setStaffVerified] = useState(1)
  const [productstatus, setProductStatus] = useState('Awaiting Approval')
  const [typeOfItem, setTypeOfItem] = useState('')
  const [shopAmericaNote,setShopAmericaNote]=useState('')
  const [supToFor,setSupToFor] = useState(null)
  const [selectPrefferedLocation,setSelectPrefferedLocation]=useState([])
  const [selectSuplierCountry,setSelectSuplierCountry]=useState([])
  const [actualWeightOfItem,setActualWeightOfItem]=useState(null)
  const [theDefaultStatus,setTheDefaultStatus]=useState(null)
  const [isLoading, setIsLoading] = useState(true);


 
  const [salesTax,setSalesTax] = useState(0)
  const navigate = useNavigate()
  const { id } = useParams()
  const state = useLocation()
  const link = state.state
  // console.log("#################Params",link);

  useEffect(() => {
    axios.get(`${REST_API}webservice/admin/get-product/${id}`)
    .then((res) => {
      console.log('setViewData-------------', res.data.result)
      setViewData(res.data.result)
      setTheDefaultStatus(res.data.result.status)
      // setLength(res.data.result.l_inches)
      // setBredth(res.data.result.b_inches)
      // setHeight(res.data.result.h_inches)
      // setShopAmericaNote(res.data.result.shop_america_note)
      // setSupToFor(res.data.result.cost_of_shipping)
   
    }).catch(err =>console.log(err))
    .finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); 
    })
  }, [id])

  useEffect(() => {
    axios
      .get(`${REST_API}webservice/admin/get-country`)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("get all country",res.data.data);
          setSupCountries(res.data.data)
        } else {
          console.log('not getting all country')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-pickup-location`)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("get all pickup locaion",res.data)
          setPickupLocations(res.data.data)
        } else {
          console.log('not getting pickup location')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-forwarder-fee`)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("getAll-forwarder-fee",res.data.data)
          setForwarderFees(res.data.data)
        } else {
          console.log('not getting forwarder fee')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-service-fee`)
      .then((res) => {
        if (res.data.status === 1) {
          // console.log("get all service fee",res.data);
          setServiceChargeData(res.data.data)
        } else {
          console.log('not getting service fee')
        }
      })
      .catch((err) => console.log(err))

    axios.get(`${REST_API}webservice/admin/getAll-vat`).then((res) => {
      if (res.data.status === 1) {
        // console.log("get all vat",res.data.data);
        setVatData(res.data.data)
      } else {
        console.log('not getting vat details')
      }
    })
  }, [])


  const fileInputRef = useRef();
  const [attachments, setAttachments] = useState([]);
  const [invoiceFile,setInvoiceFile] = useState([])

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; 
    setInvoiceFile(event.target.files[0])
    if (!file) {
      return;
    }

    if (!validateFile(file)) {
      return;
    }

    const newAttachment = {
      type: 'pdf', 
      name: file.name,
      url: URL.createObjectURL(file),
    };
    setFieldValue('pdfDocument', newAttachment);
  };
  
  useEffect(() => {
    return () => {
      attachments.forEach((attachment) => URL.revokeObjectURL(attachment.url));
    };
  }, [attachments]);






  useEffect(()=> {
   
      setLength(viewData?.l_inches)
      setBredth(viewData?.b_inches)
      setHeight(viewData?.h_inches)
      setShopAmericaNote(viewData?.shop_america_note)
      setSupToFor(viewData?.cost_of_shipping)
      setUnitCost(viewData?.unit_cost)
      setActualWeightOfItem(viewData?.actual_weight)
  },[viewData])
  
  const handleSupToForwarder=(e)=>{
    console.log(e.target.value);
    setSupToFor(e.target.value)
  }

  const handleWeightOfItem=(e)=>{
    setActualWeightOfItem(e.target.value)
  }

  const handleShopAmericaNoteChange = (event) => {
    setShopAmericaNote(event.target.value); 
  };

  const handleLength = (e) => {
    const L = e.target.value
    setLength(L)
    console.log(L);
    calculateTotal(L, bredth, height)
    setFieldValue('lInches', L)
  }

  const handleBredth = (e) => {
    const B = e.target.value
    setBredth(B)
    calculateTotal(length, B, height)
    setFieldValue('bInches', B)
  }

  const handleHeight = (e) => {
    const H = e.target.value
    setHeight(H)
    calculateTotal(length, bredth, H)
    setFieldValue('hInches', H)
  }

  const calculateTotal = (L, B, H) => {
    const LBH = L * B * H
    setTotal(LBH)
    setFieldValue('weight', LBH)
  }
  const handleUnitCostChange = (e) => {

    const cost = e.target.value
     console.log("cost",cost)
    setUnitCost(cost)
    calculateAmount(cost, quantity)
    setFieldValue('unitCost', cost)
  }

  const handleQuantityChange = (e) => {
    const qty = e.target.value
    setQuantity(qty)
    calculateAmount(unitCost, qty)
    setFieldValue('quantity', qty)
    setFieldValue('ForwarderFee', totalForwarderFee)
    // console.log('quantity ForwarderFeeeee', totalForwarderFee);
  }

  const calculateAmount = (cost, qty) => {
    const total = qty ? cost * qty : cost

    setAmount(total)
    setFieldValue('amount', total)
    setSubTotal(Math.round(total))

    setFieldValue('subtotal', subTotal)
    // setFieldValue('ForwarderFee', totalForwarderFee);

    console.log('ForwarderFeeeee', totalForwarderFee)
  }

  // console.log("amount Values",subTotal , totalForwarderFee ,totalServiceFee , pickupLocationFee , vatRate);

  //  console.log("fullTotal", fullTotal.toFixed(2));

  // useEffect(() => {
  //   ////////////////////////////////////////Forwarder Fee

  //   //  const totalAmount = !quantity ? unitCost : unitCost * quantity;
  //   const totalAmount = subTotal

  //   let foundFee = false

  //   const weight = values.actualWeight ? values.actualWeight : values.weight

  //   forwarderFees.forEach((feeObj) => {
  //     if (weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
  //       setTotalForwarderFee(weight * feeObj.fee);

  //       foundFee = true
  //     }
  //     /////////////////////////////////////////////////Service Fee
  //     serviceChargeData.forEach((data) => {
  //       if (theSubTotal >= data.amountFrom && theSubTotal <= data.amountTo) {
  //         const persentage = data.commission / 100
  //         const ans = theSubTotal * persentage
  //         // console.log("ans",ans.toFixed(2));
  //         setTotalServiceFee(parseInt(ans.toFixed(2)))
  //       }
  //     })

  //     /////////////////////////////////////////////////Pickup Location
  //     //  console.log("preffeeee",values.prefferedLocation?.state);
  //     pickupLocations.forEach((data) => {
  //       if (data.state === values.prefferedLocation?.state) {
  //         // console.log("cost:", data.cost);
  //         setPickupLocationFee(parseInt(data.cost))
  //       }
  //     })

  //     vatData.forEach((data) => {
  //       if (data.name === 'VAT') {
  //         // console.log(data.percentage,"VAT Percentagr");
  //         const percentage = data.percentage / 100
  //         //  console.log("percentage",percentage);

  //         setVatRate(totalServiceFee * percentage)
  //         //  console.log("vatRate", vatRate);
  //       }
  //     })
  //   })


  //   supCountries.forEach((data) => {
  //     // console.log("fsdfa",values?.prefferedLocation);
    
  //     // console.log("data",data);
  //     if (data.country === values.suplierCountry?.country ) {
  //       // console.log("cost:", data.salesTax);
  //       // console.log("theAmt:",values.quantity? values.unitCost * values.quantity :values.unitCost );
  //       let amount =values.quantity? values.unitCost * values.quantity :values.unitCost
  //       setSalesTax(parseFloat(amount * data.salesTax / 100).toFixed(2))
  //       // setPickupLocationFee(parseInt(data.cost))
  //     }
  //   });
  //   //  if (!foundFee) {
  //   //      console.log("No matching fee found for totalAmount:", totalAmount);
  //   //      setTotalForwarderFee(0)
  //   //  }
  // }, [ unitCost,quantity,handleUnitCostChange,handleQuantityChange,forwarderFees,selectPrefferedLocation,selectSuplierCountry])


  useEffect(() => {
    ////////////////////////////////////////Forwarder Fee

  //  const totalAmount = !quantity ? unitCost : unitCost * quantity;
   const totalAmount = subTotal
    
   let foundFee = false;

  //  const weight =values.actualWeight ? values.actualWeight : values.weight
  
  //  forwarderFees.forEach((feeObj) => {
  //    if (weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
  //      setTotalForwarderFee(weight * feeObj.fee);
  //      foundFee = true;
      
  //    }


  const weight =values.actualWeight ? values.actualWeight : values.weight
   const volumetricWeight=(values.lInches * values.bInches * values.hInches / 166).toFixed(2)
   const theCountry=values.suplierCountry?.country
  let volumetricWeightTotal =0
  let actualWeightTotal=0
   forwarderFees.forEach((feeObj) => {

    if(volumetricWeight) {
      if(theCountry === feeObj.country_name && feeObj.weightType === "Volumetric Weight")
      {
        console.log(feeObj,"volumetric");
        if(!feeObj.setWeightTo) {
            if( volumetricWeight > feeObj.setWeightFrom){
             let rate = volumetricWeight * feeObj.fee
             let fixedFee =feeObj.fixedFee
             volumetricWeightTotal =fixedFee > rate ? fixedFee : rate
            }else {
              console.log("check the admin side  volumetric weight from to");
            }
        }else {
          if(volumetricWeight >= feeObj.setWeightFrom && volumetricWeight <= feeObj.setWeightTo) {
            let rate = volumetricWeight * feeObj.fee
            let fixedFee =feeObj.fixedFee 
            volumetricWeightTotal =fixedFee > rate ? fixedFee : rate
          }
        }
      }            //which means  feeObj.weightType === "Actual Weight"
      
        if(theCountry === feeObj.country_name && feeObj.weightType === "Actual Weight"){
          console.log(feeObj,"actual");

          if(!feeObj.setWeightTo) {
            if( weight > feeObj.setWeightFrom){
              let rate = weight * feeObj.fee
              let fixedFee =feeObj.fixedFee
              actualWeightTotal =fixedFee > rate ? fixedFee : rate
             }else {
               console.log("check the admin side  volumetric weight from to");
             }
          }else {
            if(weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
              let rate = weight * feeObj.fee
              console.log(weight , feeObj.fee , rate,"123333");
              let fixedFee =feeObj.fixedFee 
              actualWeightTotal =fixedFee > rate ? fixedFee : rate
            }
          }

        }

        actualWeightTotal > volumetricWeightTotal ? setTotalForwarderFee(actualWeightTotal) 
                                             :setTotalForwarderFee(volumetricWeightTotal)
    }else {
      if(theCountry === feeObj.country_name && feeObj.weightType === "Actual Weight"){
        console.log(feeObj,"actual");

        if(!feeObj.setWeightTo) {
          if( weight > feeObj.setWeightFrom){
            let rate = weight * feeObj.fee
            let fixedFee =feeObj.fixedFee
            actualWeightTotal =fixedFee > rate ? fixedFee : rate
           }else {
             console.log("check the admin side  volumetric weight from to");
           }
        }else {
          if(weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
            let rate = weight * feeObj.fee
            let fixedFee =feeObj.fixedFee 
            console.log(weight , feeObj.fee , rate,fixedFee,"123333");
            actualWeightTotal =fixedFee > rate ? fixedFee : rate
          }
        }

      }

      setTotalForwarderFee(actualWeightTotal) 
     console.log("sdfassdfasdfasdf");
         
    }


    //   if(volumetricWeight) {
    //     if (feeObj.weightType === "Volumetric Weight" && volumetricWeight >= feeObj.setWeightFrom && volumetricWeight <= feeObj.setWeightTo) {
    //       volumetricWeightTotal=volumetricWeight * feeObj.fee,"puioyuih"
    //       foundFee = true;
         
    //     }
    //   }
    //  if (feeObj.weightType === "Actual Weight" && weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
    //   actualWeightTotal=weight * feeObj.fee
          
    //    foundFee = true;
      
    //  }
    //  setTotalForwarderFee((actualWeightTotal + volumetricWeightTotal).toFixed(2));


     /////////////////////////////////////////////////Service Fee

    //  serviceChargeData.forEach((data)=>{
    //   if(theSubTotal >=data. amountFrom  && theSubTotal <=data. amountTo) {
    //     const persentage=data.commission /100
    //     const ans =theSubTotal * persentage
    //     // console.log("ans",ans.toFixed(2));
    //     setTotalServiceFee(parseInt(ans.toFixed(2)));
    //   }
    //  })

    serviceChargeData.forEach((data) => {
      if(!data.amountTo) {
        if (theSubTotal >= data.amountFrom ) {
          const percentage = data.commission / 100;
          let rate = theSubTotal * percentage;
          let fixedFee = data.fixedFee;

          rate *= 100;
          rate = Math.floor(rate);
          rate /= 100;
          const ans = rate > fixedFee ? rate.toFixed(2) : fixedFee.toFixed(2);
          setTotalServiceFee(parseFloat(ans));
        }
      }else if (theSubTotal >= data.amountFrom && theSubTotal <= data.amountTo) {
          const percentage = data.commission / 100;
          let rate = theSubTotal * percentage;
          let fixedFee = data.fixedFee;
            
          console.log("percentage",percentage,fixedFee);
          // Shift decimal places
          rate *= 100;
  
          // Round down to two decimal places
          rate = Math.floor(rate);
  
          // Shift decimal places back
          rate /= 100;
          console.log(rate,fixedFee,"lllllllllllllllll");

          const ans = rate > fixedFee ? rate.toFixed(2) : fixedFee.toFixed(2);
          setTotalServiceFee(parseFloat(ans));
      }
  });

     /////////////////////////////////////////////////Pickup Location
  //  console.log("preffeeee",values.prefferedLocation?.state);

     pickupLocations.forEach((data) => {
      // console.log("fsdfa",values?.prefferedLocation);
      // console.log("values.data",values?.data);
      // console.log("data",data);
      if (data.state === values.prefferedLocation?.state ) {
        // console.log("cost:", data.cost);
        setPickupLocationFee(parseInt(data.cost))
      }
    });

   vatData.forEach((data)=> {
    if(data.name==="VAT"){
      // console.log(data.percentage,"VAT Percentagr");
      const percentage=data.percentage /100
      //  console.log("percentage",percentage);
      
      setVatRate(totalServiceFee * percentage)
      //  console.log("vatRate", vatRate);
      
    }


    })


   }); 
  //  if (!foundFee) {
  //      console.log("No matching fee found for totalAmount:", totalAmount);
  //      setTotalForwarderFee(0)
  //  }

    supCountries.forEach((data) => {
      // console.log("fsdfa",values?.prefferedLocation);
    
      // console.log("data",data);
      if (data.country === values.suplierCountry?.country ) {
        // console.log("cost:", data.salesTax);
        // console.log("theAmt:",values.quantity? values.unitCost * values.quantity :values.unitCost );
        let amount =values.quantity? values.unitCost * values.quantity :values.unitCost
        setSalesTax(parseFloat(amount * data.salesTax / 100).toFixed(2))
        // setPickupLocationFee(parseInt(data.cost))
      }
    });

  }, [unitCost, quantity,handleUnitCostChange,handleQuantityChange,forwarderFees,selectPrefferedLocation,selectSuplierCountry]);




  const statusDetails = [
    { status: 'Awaiting Approval'},
    { status: 'Approved for Payment'},
    { status: 'Payment Made'},
    { status: 'Order Placed' },
    { status: 'Ready for Shipment' },
    { status: 'Undergoing Clearing'},
    { status: 'Ready for Pickup' },
    { status: 'Marked for Demurrage' },
    { status: 'Marked for Auction' },
    // { status: 'Disapprove' },
  ]

  const UpdateUserSchema = Yup.object().shape({
    unitCost: Yup.number().required('Unit cost is required'),
    quantity: Yup.number().required('Quantity is required'),
    type: Yup.object().nullable().required('Type of item is required'),
    prefferedLocation: Yup.object()
      .nullable()
      .required('Preffered Location is required'),
    amount: Yup.number().required('amount is required'),
    costofShipping: Yup.number()
      .nullable()
      .required('Cost of shipping item from supplier to forwarder is required'),
    //  weight: Yup.number().required('Weight is required').nullable(),
    lInches: Yup.number().required('lenght is required').nullable(),
    bInches: Yup.number().required('breadth is required').nullable(),
    hInches: Yup.number().required('height is required').nullable(),
    suplierCountry: Yup.object().nullable().required('Supplier Country is required'),
    prefferedLocation: Yup.object()
      .nullable()
      .required('Preffered Location is required'),
    subtotal: Yup.number().required('Sub total is required'),
    actualWeight: Yup.string()
      .required('Actual weight of item is required')
      .nullable(),
    ShopAmericaNotes: Yup.string()
      .required('Shop America note is required')
      .nullable(),
    itemLink: Yup.string().required('Item Link is required').nullable(),
    itemName: Yup.string().required('Item Name is required').nullable(),
    supplierName: Yup.string().required('Supplier Name is required').nullable(),
    //  status:Yup.object().nullable().required('Product Status is required'),
    //    pdfDocument: Yup.object().shape({
    //   type: Yup.string().oneOf(['pdf'], 'Only PDF files are allowed'),
    //   name: Yup.string(),
    //   url: Yup.string(),
    // }),
  })
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      type: viewData
        ? top100Films?.find((option) => option.type === viewData.type_of_item)
        : null,
      itemName: viewData ? viewData.item_name : '',
      supplierName: viewData ? viewData.supplier_name : '',
      // unitCost: viewData ? viewData.unit_cost : 0,

      unitCost: viewData ? unitCost : 0,

      quantity: viewData ? viewData.quantity : 0,
      amount: viewData ? viewData.amount : null,
      // costofShipping: viewData ? viewData.cost_of_shipping : null,
      costofShipping: supToFor ? supToFor : null,
      
      weight: viewData ? viewData.weight : null,
      // actualWeight: viewData ? viewData.actual_weight : null,
      actualWeight: actualWeightOfItem ? actualWeightOfItem : null,
      // lInches: viewData ? viewData.l_inches :length,
      lInches: length?length : null,
      // bInches: viewData ? viewData.b_inches : null,
      bInches: bredth ? bredth : null,
      // hInches: viewData ? viewData.h_inches : null,
      hInches: height ? height : null,
      suplierCountry: viewData
        ? supCountries?.find((option) => option.id == viewData.supplier_country)
        : null,
      prefferedLocation: viewData
        ? pickupLocations?.find(
            (option) => option.id == viewData.preffered_location,
          )
        : null,
      subtotal: viewData ? viewData.sub_total : null,
      ForwarderFee: viewData ? viewData.forwarder_fee : 0,
      ServiceCharge: viewData ? viewData.service_charge : 0,
      vat: viewData ? viewData.vat : 0,
      customerNote: viewData ? viewData.customer_note : null,
      // ShopAmericaNotes: viewData ? viewData.shop_america_note : '',
      ShopAmericaNotes: shopAmericaNote ? shopAmericaNote : null,
      pickupLocation: viewData ? viewData.delivery_to_pickup_location : 0,
      total: viewData ? viewData.total : null,
      userId: viewData ? viewData.customer_id : null,
      // status:id? viewData.status :"Awaiting Approval",
      status: viewData
        ? statusDetails?.find((option) => option.status == viewData.status)
        : null,
      // itemLink:viewData? viewData.status:""
      itemLink: link ? link : viewData.item_link,
      staff_verified: staffVerified,
      supplierCountryTax:viewData? viewData?.supplier_country_tax : 0,
      byId:userId,
      invoice:viewData? viewData?.invoice : null,
      image:viewData? `${REST_API}${viewData.image}`:"",
      // pdfDocument:viewData? `${REST_API}${viewData.pdfDocument}`:null,
      pdfDocument:viewData? viewData.pdfDocument:null,


    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let theAmount= values.quantity? values.unitCost * values.quantity :values.unitCost
        let theSubTotal=values.costofShipping ? Number(theAmount) + Number(values.costofShipping)+ Number(salesTax)  :theAmount
        const fullTotal = ( subTotalValue + forwarderFeeValue + serviceFeeValue + pickupFeeValue + vatRateValue ).toFixed(2)

        console.log( "valuesstatussss", values.status.status)
        values.amount = theAmount
        values.ForwarderFee = totalForwarderFee
        values.ServiceCharge = totalServiceFee
        values.pickupLocation = pickupLocationFee
        values.vat = vatRate.toFixed(2)
        values.total = fullTotal
        values.subtotal=parseFloat(theSubTotal).toFixed(2)
        values.staff_verified = staffVerified
        values.status = values.status.status ? values.status.status :  productstatus 
        // values.status = productstatus.status ? productstatus.status :  productstatus 
        values.supplierCountryTax=salesTax
        console.log('Form values:', values)

        let formData = new FormData();
         
        formData.append('type', values?.type.type);
        formData.append('itemName', values?.itemName);
        formData.append('supplierName', values?.supplierName);
        formData.append('unitCost', values?.unitCost);
        formData.append('quantity', values?.quantity);
        formData.append('amount', parseFloat(theAmount).toFixed(2));
        formData.append('costofShipping', values?.costofShipping);
        formData.append('weight', values?.weight);
        formData.append('actualWeight', values?.actualWeight);
        formData.append('lInches', values?.lInches);
        formData.append('bInches', values?.bInches);
        formData.append('hInches', values?.hInches);
        formData.append('suplierCountry', values.suplierCountry.id);
        formData.append('prefferedLocation', values?.prefferedLocation.id);
        formData.append('subtotal', parseFloat(theSubTotal).toFixed(2));
        formData.append('ForwarderFee', totalForwarderFee);
        formData.append('ServiceCharge', totalServiceFee);
        formData.append('vat', vatRate.toFixed(2));
        formData.append('customerNote', values.customerNote ? values.customerNote : "");
        formData.append('ShopAmericaNotes', values.ShopAmericaNotes?values.ShopAmericaNotes : "");
        formData.append('pickupLocation', pickupLocationFee);
        formData.append('total', fullTotal);
        formData.append('userId', values.userId);
        formData.append('status', productstatus);
        formData.append('itemLink', values.itemLink);
        formData.append('supplierCountryTax', salesTax);
        formData.append('file', invoiceFile);
        formData.append('staffVerified', staffVerified);
        formData.append('save', save);
        formData.append('byId', values.byId);

        
        id? 

        await axios.post(`${REST_API}webservice/admin/update-product/${id}`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      })  .then((res) => {
        if (res.data.status === 1) {
          console.log(res.data)
          enqueueSnackbar(res.data.message, { variant: 'success' })
          navigate(PATH_DASHBOARD.orderHistory.root)
        }
        enqueueSnackbar(res.data.message, { variant: 'error' })
      })
      .catch((err) => console.log(err))



        // axios.post(
        //         `${REST_API}webservice/admin/update-product/${id}`,
        //         { values, save },
        //         {
        //           headers: {
        //             'Content-Type': 'application/json',
        //           },
        //         },
        //       )
        //       .then((res) => {
        //         if (res.data.status === 1) {
        //           console.log(res.data)
        //           enqueueSnackbar(res.data.message, { variant: 'success' })
        //           navigate(PATH_DASHBOARD.orderHistory.root)
        //         }
        //         enqueueSnackbar(res.data.message, { variant: 'error' })
        //       })
        //       .catch((err) => console.log(err))


          : axios
              .post(`${REST_API}webservice/admin/add-new-product`, {
                values,
                save,
              })
              .then((res) => {
                if (res.data.status === 1) {
                  console.log(res.data.message)
                  enqueueSnackbar(res.data.message, { variant: 'success' })
                  navigate(PATH_DASHBOARD.general.app)
                } else {
                  console.log(res.data.message)
                  enqueueSnackbar(res.data.message, { variant: 'error' })
                }
              })

        if (isMountedRef.current) {
          setSubmitting(false)
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        setFieldValue('photoURL', {
          ...file,
          preview: URL.createObjectURL(file),
        })
      }
    },
    [setFieldValue],
  )

  const handleAgrementSubmit = () => {
    setAgreement(!agreement)
  }

  // const handleSave = async () => {
  //   try {
  //     await UpdateUserSchema.validate(formik.values, { abortEarly: false })
  //     setStaffVerified(2)
  //     setSave(1)
  //     handleSubmit()
  //   } catch (error) {
  //     // alert("Please fill in all required fields.");
  //     enqueueSnackbar('Please fill in all required fields.', {
  //       variant: 'error',
  //     })
  //     console.error('Validation Error:', error)
  //   }
  // }

  const handleSave = async () => {
    try {
      await UpdateUserSchema.validate(formik.values, { abortEarly: false });
      setStaffVerified(1);
      setSave(3);
      handleSubmit();
    } catch (error) {
      if (error.inner) {
        const fieldErrors = error.inner.map(err => ({
          field: err.path,
          message: err.message,
        }));
        console.error('Validation Errors:', fieldErrors);
      } else {
        console.error('Validation Error:', error);
      }
      
      enqueueSnackbar('Please fill in all required fields.', {
        variant: 'error',
      });
    }
  }
  

  const handleSubmitting = async () => {
    try {
      await UpdateUserSchema.validate(formik.values, { abortEarly: false })
      //  setStaffVerified(2)
      //  setSave(1)
      handleSubmit()
    } catch (error) {
      enqueueSnackbar('Please fill in all required fields.', {
        variant: 'error',
      })
      console.error('Validation Error:', error)
    }
  }

  const handleDisAgree = () => {
    setAgree(false)
    setAgreement(false)
  }

  let theAmount= values.quantity? values.unitCost * values.quantity :values.unitCost

  // console.log("theAmount",theAmount);
  // let theSubTotal = values.costofShipping
  //   ? Number(theAmount) + Number(values.costofShipping)
  //   : theAmount

  let theSubTotal=values.costofShipping ? Number(theAmount) + Number(values.costofShipping) + Number(salesTax) : Number(theAmount) + Number(salesTax)

  // let theFinalTotal=  Number(theSubTotal) + Number(totalForwarderFee)
  //                     + Number(totalServiceFee) + Number(pickupLocationFee) + Number(vatRate)

  let subTotalValue = theSubTotal ? Number(theSubTotal) : 0
  let forwarderFeeValue = totalForwarderFee ? Number(totalForwarderFee) : 0
  let serviceFeeValue = totalServiceFee ? Number(totalServiceFee) : 0
  let pickupFeeValue = pickupLocationFee ? Number(pickupLocationFee) : 0
  let vatRateValue = vatRate ? Number(vatRate) : 0

  // const fullTotal = Math.round(
  //   subTotalValue +
  //     forwarderFeeValue +
  //     serviceFeeValue +
  //     pickupFeeValue +
  //     vatRateValue,
  // )

  const fullTotal = ( subTotalValue + forwarderFeeValue + serviceFeeValue + pickupFeeValue + vatRateValue ).toFixed(2)

  let theFinalTotal = 0

  // Check and add theSubTotal if available
  if (!isNaN(theSubTotal)) {
    theFinalTotal += Number(theSubTotal)
  }

  // Check and add totalForwarderFee if available
  if (!isNaN(totalForwarderFee)) {
    theFinalTotal += Number(totalForwarderFee)
  }

  // Check and add totalServiceFee if available
  if (!isNaN(totalServiceFee)) {
    theFinalTotal += Number(totalServiceFee)
  }

  // Check and add pickupLocationFee if available
  if (!isNaN(pickupLocationFee)) {
    theFinalTotal += Number(pickupLocationFee)
  }

  // Check and add vatRate if available
  if (!isNaN(vatRate)) {
    theFinalTotal += Number(vatRate)
  }

  let weightCalcBySystem = values.lInches * values.bInches * values.hInches

  // console.log(theSubTotal,totalForwarderFee,totalServiceFee,pickupLocationFee,vatRate);



  // const filteredUpdateStatusDetails = statusDetails.filter((statusDetail) => {
  //   // Check the value of 'theDefaultStatus' and filter accordingly
  //   switch (theDefaultStatus) {
  //     case "Awaiting Approval":
  //       return statusDetail.status !== "Awaiting Approval";
  //     case "Approved for Payment":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment"  && statusDetail.status !== "Disapprove";
  //     case "Payment Made":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Disapprove";
  //     case "Ready for Shipment":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Disapprove";
  //     case "Undergoing Clearing":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Disapprove";
  //     case "Ready for Pickup":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Disapprove";
  //     case "Order Placed":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Disapprove";
  //     case "Marked for Demurrage":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Marked for Demurrage" && statusDetail.status !== "Disapprove";
  //     case "Marked for Auction":
  //       return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Marked for Demurrage" && statusDetail.status !== "Marked for Auction" && statusDetail.status !== "Disapprove";
  //     default:
  //       return true; 
  //   }
  // });


  const filteredUpdateStatusDetails = statusDetails.filter((statusDetail) => {
    // Check the value of 'theDefaultStatus' and filter accordingly
    switch (theDefaultStatus) {
      case "Awaiting Approval":
        return statusDetail.status !== "Awaiting Approval";
      case "Approved for Payment":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment"  && statusDetail.status !== "Disapprove";
      case "Payment Made":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Disapprove";
     case "Order Placed":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Disapprove"
        case "Ready for Shipment":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed"  && statusDetail.status  !== "Ready for Shipment" && statusDetail.status !== "Disapprove";
      case "Undergoing Clearing":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed"  && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Disapprove";
      case "Ready for Pickup":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed"  && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Disapprove";
      
      case "Marked for Demurrage":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed"  && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Marked for Demurrage" && statusDetail.status !== "Disapprove";
      case "Marked for Auction":
        return statusDetail.status !== "Awaiting Approval" && statusDetail.status !== "Approved for Payment" && statusDetail.status !== "Payment Made" && statusDetail.status !== "Order Placed"  && statusDetail.status !== "Ready for Shipment" && statusDetail.status !== "Undergoing Clearing" && statusDetail.status !== "Ready for Pickup" && statusDetail.status !== "Order Placed" && statusDetail.status !== "Marked for Demurrage" && statusDetail.status !== "Marked for Auction" && statusDetail.status !== "Disapprove";
      default:
        return true; 
    }
  });


  // function formatMoney(amount) {
  //   // Split the amount into integer and decimal parts
  //   const parts = amount.toString().split('.');
  //   const integerPart = parts[0];
  //   const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
  //   // Add commas every three digits from the right
  //   const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  //   // Concatenate integer and decimal parts and return
  //   return integerWithCommas + decimalPart;
  // }

  function formatMoney(amount) {
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    let decimalPart = parts.length > 1 ? parts[1] : '';
  
    // If decimalPart is empty or has only one digit, add a trailing zero
    if (decimalPart.length === 0) {
      decimalPart = '00';
    } else if (decimalPart.length === 1) {
      decimalPart = decimalPart + '0';
    } else {
      decimalPart = decimalPart.slice(0, 2); // Take only the first two digits
    }
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + '.' + decimalPart;
  }


  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingScreen />
    </Box>
  
    ) 
  }
  

  return (
    <FormikProvider value={formik}>
      {/* <Modal
      open={agreement}
      handleClose={()=>{
        setAgreement(false)
      }}
      modalTitle={agree ? "Order Placed" :'Service Agreements'}>
        <>
        <Divider/>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:3}}> 
          <Typography color={agree ?"green":""}>{agree ? "Your order has been submitted and being reviewed you shall get an invoice shortly" :"Service Agreement ..."}</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"end",mt:3}}>
          {
            agree ? "" :
            (
            <>
               <Button variant='contained' sx={{mr:3}} onClick={handleDisAgree}>No, i do not Agree</Button>
               
               <Button variant='contained' onClick={handleAgree}>Yes, i Agree</Button>
               </> )
          }
        </Box>
        </>
      </Modal> */}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {!home ? (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography>Item Details</Typography>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: '#32b59f',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                  width: '30%',
                  p: 1,
                  justifyContent: 'center',
                  mb: 15,
                  mt: 0,
                }}
              >
                <Autocomplete
                  select
                  fullWidth
                  options={filteredUpdateStatusDetails}
                  getOptionLabel={(option) => option.status}
                  value={values.status || null}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue('status', newValue)
                      setProductStatus(newValue)
                    } else {
                      setFieldValue('status', '')
                      setProductStatus(null)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Status"
                      label="Status"
                      error={Boolean(touched.status && errors.status)}
                      helperText={touched.status && errors.status}
                    />
                  )}
                />
              </Box>
              <Link
                underline="none"
                component={RouterLink}
                to={PATH_DASHBOARD.orderHistory.root}
              >
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#32b59f' }}
                  align="right"
                >
                  Back
                </Button>
              </Link>
            </Box>

            <Box
              sx={{
                border: '1px solid',
                borderColor: '#32b59f',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                display: { xs: 'flex', sm: 'none' },
                alignItems: 'center',
                p: 1,
                justifyContent: 'center',
                mt: 5,
              }}
            >
              <Autocomplete
                select
                fullWidth
                options={filteredUpdateStatusDetails}
                getOptionLabel={(option) => option.status}
                value={values.status || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue('status', newValue)
                    setProductStatus(newValue)
                  } else {
                    setFieldValue('status', '')
                    setProductStatus(null)
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Status"
                    label="Status"
                    error={Boolean(touched.status && errors.status)}
                    helperText={touched.status && errors.status}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          ''
        )}

        <Grid container spacing={3} sx={{ mt: '-100px' }}>
          {!home ? (
            <>
              <Grid item xs={12} md={4}>
                <Typography sx={{ display: { color: '#32b59f' } }}>
                  Order Status{' '}
                </Typography>

                <ProductTimeline date={viewData.date}  status={viewData.status} productId={viewData.id} countryId={viewData.supplier_country}/>

                <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                  {touched.photoURL && errors.photoURL}
                </FormHelperText>
              </Grid>
            </>
          ) : (
            ''
          )}
          <Grid item xs={12} md={home ? 12 : 8}>
            <Typography sx={{ display: { color: '#32b59f' } }}>
              Item Description{' '}
            </Typography>

            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {link ? (
                    <Box sx={{ width: '100%' }}>
                      <LabelStyle>Item Link</LabelStyle>
                      <TextField
                        fullWidth
                        placeholder="Paste the weblink to the product here. E.g. https://www.amazon.com/product...."
                        sx={{ color: 'blue' }}
                        disabled
                        {...getFieldProps('itemLink')}
                        error={Boolean(touched.itemLink && errors.itemLink)}
                        helperText={touched.itemLink && errors.itemLink}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: '100%' }}>
                      <LabelStyle>Item Link</LabelStyle>
                      <TextField
                        fullWidth
                        placeholder="Paste Item Link"
                        sx={{ color: 'blue' }}
                        {...getFieldProps('itemLink')}
                        error={Boolean(touched.itemLink && errors.itemLink)}
                        helperText={touched.itemLink && errors.itemLink}
                      />
                    </Box>
                  )}
                </Stack>

                {
                      viewData.image ?

                      <StatusDecriptionImg image={values.image}/>
                    : "" 
                    }

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {/* Type of item */}
                  <>
                    {id ? (
                     
                     <>
                     <Box sx={{ width: "100%" }}>
                     <LabelStyle>Type of Item</LabelStyle>
                      <Autocomplete
                        select
                        fullWidth
                        options={top100Films}
                        getOptionLabel={(option) => option.type}
                        value={values.type || null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue('type', newValue)
                            setTypeOfItem(newValue)
                          } else {
                            setFieldValue('type', '')
                            setTypeOfItem(null)
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select type"
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                          />
                        )}
                      />
                      </Box>

                      <Box sx={{width:"100%"}}>
                    <LabelStyle>Supplier Country</LabelStyle>
                     <Autocomplete
                  select
                  fullWidth
                  options={supCountries}
                  getOptionLabel={(option) => option.country}
                  value={values.suplierCountry || null}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue('suplierCountry', newValue);
                      setSelectSuplierCountry(newValue)

                    } else {
                      setFieldValue('suplierCountry', '');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Supplier Country"
                      // label="Supplier Country"
                      error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                      helperText={touched.suplierCountry && errors.suplierCountry}
                    />
                  )}
                />  
                </Box>

                <Box sx={{width:"100%"}}>
                    <LabelStyle>Preferred Pickup location</LabelStyle>
                      <Autocomplete
                        select
                        fullWidth
                        options={pickupLocations}
                        getOptionLabel={(option) => option.state}
                        value={values.prefferedLocation || null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue('prefferedLocation', newValue)
                            setSelectPrefferedLocation(newValue)
                          } else {
                            setFieldValue('prefferedLocation', '')
                            setSelectPrefferedLocation('')
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Preffered Location"
                           
                            error={Boolean(
                              touched.prefferedLocation &&
                                errors.prefferedLocation,
                            )}
                            helperText={
                              touched.prefferedLocation &&
                              errors.prefferedLocation
                            }
                          />
                        )}
                      />
                      </Box>


                            
                            </>
                    ) : (
                      <>
                      <Box sx={{ width: "100%" }}>
                      <LabelStyle>Type of Item</LabelStyle>
                      <Autocomplete
                        fullWidth
                        options={top100Films}
                        getOptionLabel={(option) => option.type}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Type Of Item"
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                          />
                        )}
                        value={values.type}
                        onChange={(event, newValue) => {
                          setFieldValue('type', newValue)
                          if (newValue && newValue.type === 'Digital') {
                            setType(true)
                            setTypeOfItem(newValue)
                          } else {
                            setType(false)
                            setTypeOfItem(null)
                          }
                        }}
                      />
                      </Box>

                      <Box sx={{width:"100%"}}>
                    <LabelStyle>Supplier Country</LabelStyle>
                    <Autocomplete
                    fullWidth
                    options={supCountries}
                    getOptionLabel={(option) => option.country}
                    renderInput={(params) => <TextField {...params}  
                    error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                    helperText={touched.suplierCountry && errors.suplierCountry}/>}
                    value={values.suplierCountry}
                    onChange={(event, newValue) => {
                      setFieldValue('suplierCountry', newValue);
                      setSelectSuplierCountry(newValue)
                      
                    }}
                  />
                  </Box>

                  <Box sx={{width:"100%"}}>
                    <LabelStyle>Preferred Pickup location</LabelStyle>
                      <Autocomplete
                        fullWidth
                        options={pickupLocations}
                        getOptionLabel={(option) => option.state}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                           
                            error={Boolean(
                              touched.prefferedLocation &&
                                errors.prefferedLocation,
                            )}
                            helperText={
                              touched.prefferedLocation &&
                              errors.prefferedLocation
                            }
                          />
                        )}
                        value={values.prefferedLocation}
                        onChange={(event, newValue) => {
                          setFieldValue('prefferedLocation', newValue)
                          setSelectPrefferedLocation(newValue)
                        }}
                      />
                      </Box>


                      </>
                    )}
                  </>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Item Name"
                    placeholder="Enter Item Name"
                    defaultValue={id ? 'Item Name' : ''}
                    {...getFieldProps('itemName')}
                    error={Boolean(touched.itemName && errors.itemName)}
                    helperText={touched.itemName && errors.itemName}
                  />

                  <TextField
                    fullWidth
                    label="Supplier"
                    placeholder="Enter Supplier Name"
                    defaultValue={id ? 'Supplier' : ''}
                    {...getFieldProps('supplierName')}
                    error={Boolean(touched.supplierName && errors.supplierName)}
                    helperText={touched.supplierName && errors.supplierName}
                  />
                </Stack>

                {/* {
        id ? 
                    <>
                     <Box sx={{width:"100%"}}>
                    <LabelStyle>Supplier Country</LabelStyle>
                     <Autocomplete
                  select
                  fullWidth
                  options={supCountries}
                  getOptionLabel={(option) => option.country}
                  value={values.suplierCountry || null}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFieldValue('suplierCountry', newValue);
                      setSelectSuplierCountry(newValue)

                    } else {
                      setFieldValue('suplierCountry', '');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Supplier Country"
                      // label="Supplier Country"
                      error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                      helperText={touched.suplierCountry && errors.suplierCountry}
                    />
                  )}
                />  
                </Box>

                


                    </>
             
                    :
                    <>
                    <Box sx={{width:"100%"}}>
                    <LabelStyle>Supplier Country</LabelStyle>
                    <Autocomplete
                    fullWidth
                    options={supCountries}
                    getOptionLabel={(option) => option.country}
                    renderInput={(params) => <TextField {...params}  
                    error={Boolean(touched.suplierCountry && errors.suplierCountry)}
                    helperText={touched.suplierCountry && errors.suplierCountry}/>}
                    value={values.suplierCountry}
                    onChange={(event, newValue) => {
                      setFieldValue('suplierCountry', newValue);
                      setSelectSuplierCountry(newValue)
                      
                    }}
                  />
                      </Box>

               
                 
                    </>
                  } */}


                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField  placeholder='$50' {...getFieldProps('unitCost')} label="Unit Cost"
                    // InputProps={{
                    //     type: 'number',
                    //     inputProps: {
                    //         min: 0,
                    //       }
                    //   }}
                    InputProps={{
                      type: 'number',
                      inputProps: {
                          min: 0,
                        },
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}  
                    error={Boolean(touched.unitCost && errors.unitCost)}
                    helperText={touched.unitCost && errors.unitCost}
                    onChange={(event)=>
                      handleUnitCostChange(event)
                    }
                    value={values.unitCost? values.unitCost : unitCost}/>

                  <TextField
                    label="Quantity"
                    placeholder="1"
                    {...getFieldProps('quantity')}
                    InputProps={{
                      type: 'number',
                      inputProps: {
                        min: 0,
                      },
                    }}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                    onChange={(event)=>handleQuantityChange(event)}
                    value={values.quantity ? values.quantity : quantity}
                  />

                  <TextField
                    fullWidth
                    label="Amount"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    {...getFieldProps('amount')}
                    // value={
                    //   values.amount
                    //     ? values.amount
                    //     : values.quantity // Change 'quantity' to 'values.quantity'
                    //     ? unitCost * values.quantity // Change 'quantity' to 'values.quantity'
                    //     : unitCost
                    //   }
                    //  value={amount? amount : "0.00"}
                    //  value={values.quantity? values.unitCost* values.quantity :values.unitCost}
                    // value={theAmount ? theAmount : '0.00'}
                    value={theAmount? formatMoney(parseFloat(theAmount).toFixed(2)) : "0.00"}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Stack>

                    
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>

                <Box sx={{width:"100%"}}>
                    <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Supplier Country's Tax</LabelStyle>
                  <TextField sx={{backgroundColor:"whitesmoke"}} fullWidth 
                                 defaultValue={"$0.00"} placeholder="$0.00" 
                                {...getFieldProps('supplierCountryTax')}  
                                value={salesTax? salesTax : 0}
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}  
                                error={Boolean(touched.supplierCountryTax && errors.supplierCountryTax)}
                                helperText={touched.supplierCountryTax && errors.supplierCountryTax}/>
                                
                                 </Box>

                                 <Box sx={{ width: '100%' }}>
                    <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      Cost of Shipping item from supplier to forwarder
                    </LabelStyle>
                    <TextField
                      placeholder="0"
                      fullWidth
                      {...getFieldProps('costofShipping')}
                      onChange={(event)=>handleSupToForwarder(event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        type: 'number',
                        inputProps: {
                          min: 1,
                        },
                      }}
                      // value={totalForwarderFee}
                      error={Boolean(
                        touched.costofShipping && errors.costofShipping,
                      )}
                      helperText={
                        touched.costofShipping && errors.costofShipping
                      }
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                  <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Actual weight of item (pound)</LabelStyle>
                  <TextField
                    fullWidth
                    InputProps={{
                      type: 'number',
                      inputProps: {
                        min: 0,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          lb
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('actualWeight')}
                    onChange={(event)=>handleWeightOfItem(event)}
                    error={Boolean(touched.actualWeight && errors.actualWeight)}
                    helperText={touched.actualWeight && errors.actualWeight}
                  />
                </Box>



</Stack>

                {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>
                      Cost of Shipping item from supplier to forwarder
                    </LabelStyle>
                    <TextField
                      placeholder="0"
                      fullWidth
                      {...getFieldProps('costofShipping')}
                      onChange={(event)=>handleSupToForwarder(event)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                        type: 'number',
                        inputProps: {
                          min: 1,
                        },
                      }}
                      // value={totalForwarderFee}
                      error={Boolean(
                        touched.costofShipping && errors.costofShipping,
                      )}
                      helperText={
                        touched.costofShipping && errors.costofShipping
                      }
                    />
                  </Box>
                </Stack> */}

                {/* <Box sx={{ width: '100%' }}>
                  <LabelStyle>Actual weight of item (pound)</LabelStyle>
                  <TextField
                    fullWidth
                    InputProps={{
                      type: 'number',
                      inputProps: {
                        min: 0,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          lb
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps('actualWeight')}
                    onChange={(event)=>handleWeightOfItem(event)}
                    error={Boolean(touched.actualWeight && errors.actualWeight)}
                    helperText={touched.actualWeight && errors.actualWeight}
                  />
                </Box> */}

                {
                  // type ? "" :(
                  // typeOfItem=="Digital" ? "" :(
                  viewData.type_of_item === 'Digital' || type ? (
                    ''
                  ) : (
                    <>
                      <Typography fontSize={'medium'}>
                        Volumetric weight of item (pounds)
                      </Typography>
                      <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        spacing={2}
                      >
                        <Box sx={{ width: '100%' }}>
                          <LabelStyle>L(inches)</LabelStyle>
                          <TextField
                            fullWidth
                            {...getFieldProps('lInches')}
                            InputProps={{
                              type: 'number',
                              inputProps: {
                                min: 0,
                              },
                            }}
                            
                            onChange={(event)=>
                              handleLength(event)
                            }
                            value={values.lInches ? values.lInches : length}
                            error={Boolean(touched.lInches && errors.lInches)}
                            helperText={touched.lInches && errors.lInches}
                          />
                        </Box>

                        <Box sx={{ width: '100%' }}>
                          <LabelStyle>B(inches)</LabelStyle>
                          <TextField
                            fullWidth
                            {...getFieldProps('bInches')}
                            InputProps={{
                              type: 'number',
                              inputProps: {
                                min: 0,
                              },
                            }}
                           
                            onChange={(event)=>
                              handleBredth(event)
                            }
                            value={values.bInches ? values.bInches : bredth}
                            error={Boolean(touched.bInches && errors.bInches)}
                            helperText={touched.bInches && errors.bInches}
                            // error={(!values.bInches && touched.bInches)}
                            // helperText={(!values.bInches && touched.bInches && 'Breadth is Required')}
                          />
                        </Box>

                        <Box sx={{ width: '100%' }}>
                          <LabelStyle>H(inches)</LabelStyle>
                          <TextField
                            fullWidth
                            {...getFieldProps('hInches')}
                            InputProps={{
                              type: 'number',
                              inputProps: {
                                min: 0,
                              },
                            }}
                           
                            onChange={(event)=>
                              handleHeight(event)
                            }
                            value={values.hInches ? values.hInches : height}
                            error={Boolean(touched.hInches && errors.hInches)}
                            helperText={touched.hInches && errors.hInches}
                            // error={(!values.hInches && touched.hInches)}
                            // helperText={(!values.hInches && touched.hInches && 'Height is Required')}
                          />
                        </Box>

                        <Box sx={{width:"100%"}}>
                      <LabelStyle style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>Volumetric Weight calculated by system</LabelStyle>
                        <TextField
                          fullWidth
                       
                          disabled
                          defaultValue={'0.00'}
                          {...getFieldProps('weight')}
                          // value={total}
                          // value={values.lInches * values.bInches * values.hInches}
                          // value={weightCalcBySystem ? weightCalcBySystem : null}
                          value={(values.lInches * values.bInches * values.hInches / 166).toFixed(2)}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                lb
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.weight && errors.weight)}
                          helperText={touched.weight && errors.weight}
                          // error={(!values.weight && touched.weight)}
                          // helperText={(!values.weight && touched.weight && 'Weight is Required')}
                        />
                        </Box>

                      </Stack>
                      {/* <Stack
                        direction={{
                          xs: 'column',
                          md: 'row'
                        }}
                        spacing={2}
                      >              
                        <Box sx={{width:"100%"}}>
                      <LabelStyle>Volumetric Weight calculated by system</LabelStyle>
                        <TextField
                          fullWidth
                       
                          disabled
                          defaultValue={'0.00'}
                          {...getFieldProps('weight')}
                          // value={total}
                          // value={values.lInches * values.bInches * values.hInches}
                          // value={weightCalcBySystem ? weightCalcBySystem : null}
                          value={(values.lInches * values.bInches * values.hInches / 166).toFixed(2)}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                lb
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.weight && errors.weight)}
                          helperText={touched.weight && errors.weight}
                          // error={(!values.weight && touched.weight)}
                          // helperText={(!values.weight && touched.weight && 'Weight is Required')}
                        />
                        </Box>
                      </Stack> */}
                    </>
                  )
                }
{/* 
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {id ? (
                    <>
                     
                 
                 <Box sx={{width:"100%"}}>
                    <LabelStyle>Preferred Pickup location</LabelStyle>
                      <Autocomplete
                        select
                        fullWidth
                        options={pickupLocations}
                        getOptionLabel={(option) => option.state}
                        value={values.prefferedLocation || null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue('prefferedLocation', newValue)
                            setSelectPrefferedLocation(newValue)
                          } else {
                            setFieldValue('prefferedLocation', '')
                            setSelectPrefferedLocation('')
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Preffered Location"
                           
                            error={Boolean(
                              touched.prefferedLocation &&
                                errors.prefferedLocation,
                            )}
                            helperText={
                              touched.prefferedLocation &&
                              errors.prefferedLocation
                            }
                          />
                        )}
                      />
                      </Box>
                    </>
                  ) : (
                    <>
                    

                   
      <Box sx={{width:"100%"}}>
                    <LabelStyle>Preferred Pickup location</LabelStyle>
                      <Autocomplete
                        fullWidth
                        options={pickupLocations}
                        getOptionLabel={(option) => option.state}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                           
                            error={Boolean(
                              touched.prefferedLocation &&
                                errors.prefferedLocation,
                            )}
                            helperText={
                              touched.prefferedLocation &&
                              errors.prefferedLocation
                            }
                          />
                        )}
                        value={values.prefferedLocation}
                        onChange={(event, newValue) => {
                          setFieldValue('prefferedLocation', newValue)
                          setSelectPrefferedLocation(newValue)
                        }}
                      />
                      </Box>
                    </>

                  )}
                </Stack> */}

                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  display={'flex'}
                  justifyContent={'end'}
                >
                  <TextField
                    label="Sub Total"
                    defaultValue={'$00'}
                    placeholder="$50"
                    {...getFieldProps('subtotal')}
                    //  value={subTotal ? subTotal : "0.00"}
                    //  value={values.costofShipping ?  Number(values.amount) + Number(values.costofShipping)
                    //           :values.amount}
                    // value={theSubTotal ? theSubTotal : '0.00'}
                    value={theSubTotal? formatMoney(parseFloat(theSubTotal).toFixed(2)) : "0.00"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                  }}
                    error={Boolean(touched.subtotal && errors.subtotal)}
                    helperText={touched.subtotal && errors.subtotal}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Shipping Charges
                  </Typography>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                  >
                    Service Charges
                  </Typography>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                  >
                    Shipping Charges
                  </Typography>
                  <TextField
                    sx={{ backgroundColor: 'whitesmoke' }}
                    fullWidth
                    label="Forwarder Fee"
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('ForwarderFee')}
                    value={totalForwarderFee ? totalForwarderFee.toFixed(2) : '0.00'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.ForwarderFee && errors.ForwarderFee)}
                    helperText={touched.ForwarderFee && errors.ForwarderFee}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                    label="Service Charge"
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('ServiceCharge')}
                    value={totalServiceFee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.ServiceCharge && errors.ServiceCharge,
                    )}
                    helperText={touched.ServiceCharge && errors.ServiceCharge}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                    label="Delivery to pickup Location"
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('pickupLocation')}
                    value={pickupLocationFee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.pickupLocation && errors.pickupLocation,
                    )}
                    helperText={touched.pickupLocation && errors.pickupLocation}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                  >
                    Service Charges
                  </Typography>

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                    label="Delivery to pickup Location"
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('pickupLocation')}
                    value={pickupLocationFee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.pickupLocation && errors.pickupLocation,
                    )}
                    helperText={touched.pickupLocation && errors.pickupLocation}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                    label="Service Charge"
                    disabled
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('ServiceCharge')}
                    value={totalServiceFee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.ServiceCharge && errors.ServiceCharge,
                    )}
                    helperText={touched.ServiceCharge && errors.ServiceCharge}
                  />

                  <TextField
                    sx={{ backgroundColor: 'whitesmoke' }}
                    fullWidth
                    label="VAT on Service Charge"
                    defaultValue={'$0.00'}
                    placeholder="$0.00"
                    {...getFieldProps('vat')}
                    value={vatRate ? vatRate.toFixed(2) : '0.00'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.vat && errors.vat)}
                    helperText={touched.vat && errors.vat}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography sx={{ width: '50%', color: '#32b59f' }}>
                    Notes
                  </Typography>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Customer Note</LabelStyle>
                    <TextField
                      sx={{ backgroundColor: 'whitesmoke' }}
                      fullWidth
                      disabled
                      placeholder="Enter Notes"
                      {...getFieldProps('customerNote')}
                      error={Boolean(
                        touched.customerNote && errors.customerNote,
                      )}
                      helperText={touched.customerNote && errors.customerNote}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>ShopAmerica’s Note</LabelStyle>
                    <TextField
                      fullWidth
                      placeholder="Note"
                      {...getFieldProps('ShopAmericaNotes')}
                     onChange={(event)=>handleShopAmericaNoteChange(event)}
                      error={Boolean(
                        touched.ShopAmericaNotes && errors.ShopAmericaNotes,
                      )}
                      helperText={
                        touched.ShopAmericaNotes && errors.ShopAmericaNotes
                      }
                    />
                  </Box>
                </Stack>

              
                  {/* <TextField
                    sx={{ backgroundColor: 'whitesmoke' }}
                    fullWidth
                    label="Invoice"
                    placeholder="Paste the invoice link"
                    {...getFieldProps('invoice')}
                    error={Boolean(touched.invoice && errors.invoice)}
                    helperText={touched.invoice && errors.invoice}
                  /> */}

                           <Box>
                    <Typography variant='h6' sx={{mt:2,mb:2}}>{viewData.status === "Awaiting Approval" ? "" :"Invoice"}</Typography>
                    {
                          // viewData.pdfDocument?
                          viewData.pdfDocument || viewData.status === "Awaiting Approval" ?
                          ""
                          :
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                      <Button variant="outlined" sx={{color:"red"}} onClick={() => fileInputRef.current.click()} 
                      startIcon={<UploadFile />}
                      >
                        Upload Invoice
                      </Button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        accept=".pdf"
                      />
                    </Box>


}
{viewData.pdfDocument &&   (
                      <Box  my={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                          {/* <MButton
                            color="error"
                            size="small"
                            startIcon={<Icon icon={trash2Fill} />}
                            onClick={() => setFieldValue('pdfDocument', null)}
                            sx={{ mt: -1 }}
                          >
                            Delete
                          </MButton> */}
                        </Box>
                        <iframe src={`${REST_API}${viewData.pdfDocument}`} type="application/pdf" width="100%" height="400px" />
                      </Box>
                    ) }

                  {values.pdfDocument && !viewData.pdfDocument ?(
                      <Box  my={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                          <MButton
                            color="error"
                            size="small"
                            startIcon={<Icon icon={trash2Fill} />}
                            onClick={() => setFieldValue('pdfDocument', null)}
                            sx={{ mt: -1 }}
                          >
                            Delete
                          </MButton>
                        </Box>
                        <iframe src={values.pdfDocument.url} type="application/pdf" width="100%" height="400px" />
                      </Box>
                    ) : ""}
                  </Box>

           
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                  sx={{ backgroundColor: 'whitesmoke' }}
                  label="Total"
                  defaultValue={'00'}
                  placeholder="50"
                  {...getFieldProps('total')}
                  //  value={theFinalTotal ? theFinalTotal.toFixed(2): 0.00}
                  value={fullTotal ? formatMoney(fullTotal) : '0.00'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.total && errors.total)}
                  helperText={touched.total && errors.total}
                />
              </Box>

              {/* <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>

              </Box> */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  mt: 2,
                }}
              >
                {home ? (
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={PATH_DASHBOARD.root}
                  >
                    <Button
                      variant="contained"
                      sx={{ mr: 3, backgroundColor: '#32b59f' }}
                    >
                      Close
                    </Button>
                  </Link>
                ) : (
                  <Link
                    underline="none"
                    component={RouterLink}
                    to={PATH_DASHBOARD.orderHistory.root}
                  >
                    <Button
                      variant="contained"
                      sx={{ mr: 3, backgroundColor: '#32b59f' }}
                    >
                      Close
                    </Button>
                  </Link>
                )}
                {/* <Button variant='contained' sx={{backgroundColor:"#32b59f",mr:3}} onClick={madePayment}>I have Made payment</Button> */}
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#32b59f', mr: 3 }}
                  onClick={handleSave}
                >
                  Save
                </Button>

                {/* <Button variant='contained' sx={{backgroundColor:"#32b59f"}} onClick={handleSubmitting} >Submit</Button> */}

                <LoadingButton
                  type="submit"
                  sx={{ backgroundColor: '#32b59f', mr: 3 }}
                  variant="contained"
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
