//  const REST_API = 'https://shopamerica-api.skyniche.website/'
// const REST_API = 'https://shopamerica.ng/staff/'
 const REST_API = 'https://api.shopamerica.ng/'


// const REST_API = 'http://127.0.0.1:5000/'

// const URL="http://localhost:3002/"
const URL="https://adminsnfa.skyniche.website/"


module.exports={REST_API,URL}