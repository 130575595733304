import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography, Stack, Container,
  Autocomplete

} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddUserForm from './AddUserForm';
// ----------------------------------------------------------------------


const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id:1,
    subtotalRange:"$ 51 - $100",
    commission:'3%',
    country:"USA"
  },{
    id:2,
    subtotalRange:"$ 0 - $50",
    commission:'2%',
    country:"China"
  },
  ,{
    id:2,
    subtotalRange:"$ 0 - $50",
    commission:'2%',
    country:"India"
  },
];

// ----------------------------------------------------------------------

export default function AddUser() {
  const theme = useTheme();
  const navigate=useNavigate()


  const handleBack=()=> {
    navigate(PATH_DASHBOARD.customerDetails.root)
  }
  return (
    <>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Customers / add customer</Typography>

   </Box>
    <Card>  
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title="Add New Customer" sx={{ mb: 1,fontSize:"small" }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }} onClick={handleBack}
    >
     Back 
    </Button>
        </Box>
      <Divider sx={{mt:3}}/>
       <Container sx={{mt:5}}>

           <AddUserForm/>
                 
  
          {/* <Box sx={{display:"flex",justifyContent:"end",mb:3}}>
          <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f"}}}
    onClick={handleBack}>
      Cancel 
    </Button>
    <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f", 
        }}}>
      Add Customer
    </Button>
          </Box> */}
      </Container>


      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
    </Card>
    </>

  );
}
